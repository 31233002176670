import { useEffect } from 'react'
import { useRouter } from 'next/router'

const Index = () => {
  const router = useRouter()
  const NEXT_PUBLIC_REALM = process?.env?.NEXT_PUBLIC_REALM
  const NEXT_PUBLIC_NETWORK = process?.env?.NEXT_PUBLIC_NETWORK



  useEffect(() => {
    const mainUrl = NEXT_PUBLIC_REALM ? `/dao/${NEXT_PUBLIC_REALM}${NEXT_PUBLIC_NETWORK}` : '/bigsurs'
    if (!router.asPath.includes(mainUrl)) {
      router.replace(mainUrl)
    }
  }, [NEXT_PUBLIC_REALM])

  return null
}

export default Index
